<script setup>
import { formatWidth, formatHeight } from '~/models/layout/style';
import TheAdComponent from './TheAdComponent.vue';
const props = defineProps({
  data: {
    type: Object,
    required: true,
  }
})

const formattedStyle = computed(() => {
  const { maxHeight, maxWidth } = props.data.style;

  const h = maxHeight ? { maxHeight: formatHeight(maxHeight) } : null;
  const w = maxWidth ? { maxWidth: formatWidth(maxWidth) } : null;

  return { ...h, ...w };
});
</script>
<template>
  <div :style="formattedStyle">
    <TheAdComponent
      :key="props.data.id"
      :data="props.data"
    />
  </div>
</template>